<template>
    <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn class="no-print"/>
                <div class="container-fluid text-dark">
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 class="text-dark mb-0" > Projects</h3>
                    </div>
                    <hr>
                    <div class="row">
                        <button class="btn btn-success col-xl-2 col-lg-2 col-md-2 col-sm-12" style="height: 39px;" data-toggle="modal" data-target="#mdl_project" @click="clearProject()">
                            Add Project &nbsp;
                            <i class="fa fa-plus"></i>
                        </button>
                        <button class="btn btn-warning col-xl-2 col-lg-2 col-md-2 ms-2 col-sm-12" style="height: 39px;" data-toggle="modal" data-target="#mdl_staff_project" @click="clearStaffProject()">
                            Staff Projects &nbsp;
                        </button>
                        <a class="btn btn-primary text-white col-xl-2 col-md-5 mx-2 mb-2" href="/work_projects_report"> Report </a>
                        <button class="btn btn-primary" style="height: 39px;" data-toggle="modal" data-target="#mdlSortProjects"> <v-icon color="white"> mdi-sort </v-icon> </button>
                    </div>
                </div>

                <v-data-table class="mx-2 mt-3" :headers="headers" :items="work_projects">
                    
                    <template v-slot:[`item.work_project_status`]="{item}">
                        <v-btn icon color="success" @click="updateStatus(item.work_project_id, 'disabled')" v-if="item.work_project_status == 'enabled'"> <v-icon> fas fa-check </v-icon> </v-btn>
                        <v-btn icon color="error" @click="updateStatus(item.work_project_id, 'enabled')" v-else> <v-icon> mdi-cancel </v-icon> </v-btn>
                    </template>

                    <template v-slot:[`item.actions`]="{item}">
                        <v-btn icon color="primary" @click="getSingleProject(item)" data-toggle="modal" data-target="#mdl_project"> <v-icon> mdi-pencil </v-icon> </v-btn>
                        <v-btn icon color="error" @click="deleteProject(item)" > <v-icon> mdi-delete </v-icon> </v-btn>
                    </template>
                </v-data-table>

            </div>
        </div>

        <!-- Modals -->
        <change-pass/>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_project">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" @click="clearProject()" data-dismiss="modal">&times;</button>
                        <h5 class="mt-2">Project Information</h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid px-0">
                            <div class="row pe-4">
                                <label class="col-2 mt-2 ps-4">Project: </label>
                                <input type="text" class="form-control col-10" v-model="work_project.work_project_name" @keyup.enter="work_project.work_project_id ? updateProject() : addProject()">
                            </div>
                            <button class="btn btn-success btn-block mt-3" @click="addProject()" :disabled="loading_add_project" v-if="!work_project.work_project_id">
                                <i class="fa fa-plus"></i>
                            </button>
                            <button class="btn btn-warning btn-block mt-3" @click="updateProject()" :disabled="loading_add_project" v-else>
                                <i class="fa fa-save"></i>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_staff_project">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" @click="clearStaffProject()" data-dismiss="modal">&times;</button>
                        <h5 class="mt-2">Staff Projects</h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid px-0">
                            <div class="row pe-4">
                                <label class="col-2 mt-2 ps-4">Staff: </label>
                                <select class="form-control col-7" v-model="staff_project.st_id" disabled>
                                    <option value=""></option>
                                    <option v-for="staff in staffs" :key="staff.st_id" :value="staff.st_id"> {{ staff.staff_name }} </option>
                                </select>
                                <span class="col-1">
                                    <v-checkbox class="mt-0" v-model="showSpecialStaffs" true-value="true" false-value="false"></v-checkbox>
                                </span>
                                <span class="col-2" style="margin-top: 6px; margin-inline-start: -29px;">
                                    Specials
                                </span>
                            </div>
                            <div class="row pe-4 mt-3">
                                <label class="col-2 mt-2 ps-4">Project: </label>
                                <select class="form-control col-10" v-model="staff_project.work_project_id" >
                                    <option value=""></option>
                                    <option v-for="project in work_projects" :key="project.work_project_id" :value="project.work_project_id"> {{ project.work_project_name }} </option>
                                </select>
                            </div>
                            <button class="btn btn-warning btn-block mt-3" @click="updateStaffProject()" :disabled="!staff_project.staff_work_project_id">
                                <i class="fa fa-save"></i>
                            </button>

                            <v-data-table class="mt-3" :headers="staff_work_project_headers" :items="filteredStaffProjects">
                                <template v-slot:[`item.actions`]="{item}">
                                    <v-btn icon color="primary" @click="selectSingleStaffProject(item)"> <v-icon> mdi-pencil </v-icon> </v-btn>
                                </template>
                            </v-data-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdlSortProjects">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal">&times;</button>
                        <h5 class="mt-2"> Sort Projects <button class="btn btn-primary ms-2" @click="saveProjectsSort()"> <v-icon color="white">  mdi-content-save </v-icon> </button> </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div style="width: 100%;">
                                <draggable v-model="projects_to_sort">
                                    <transition-group>
                                        <v-btn block color="primary" elevation="0" class="mb-2" v-for="(prj, i) in projects_to_sort" :key="'emp' + i" > {{ prj.work_project_name }} </v-btn>
                                    </transition-group>
                                </draggable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
// import showError from "../showError.js";
import draggable from 'vuedraggable'
import showError from "../showError.js";
import auth from '../auth';
// const nullValues = ["", null];

export default {
    name: "work_projects",
    components: {
        draggable
    },
    data() {
        return {
            work_project: {
                work_project_id: null,
                work_project_name: null,
                work_project_status: null
            },
            work_projects: [],
            loading_add_project: false,
            headers: [
                {
                    text: 'Project',
                    align: 'start',
                    sortable: true,
                    value: 'work_project_name',
                },
                {
                    text: 'Status',
                    align: 'start',
                    sortable: true,
                    value: 'work_project_status',
                },
                {
                    text: 'Actions',
                    align: 'start',
                    sortable: false,
                    value: 'actions',
                }
            ],
            staff_work_project_headers: [
                {
                    text: 'Staff',
                    align: 'start',
                    sortable: true,
                    value: 'staff_name',
                },
                {
                    text: 'Project',
                    align: 'start',
                    sortable: true,
                    value: 'work_project_name',
                },
                {
                    text: 'Actions',
                    align: 'start',
                    sortable: false,
                    value: 'actions',
                }
            ],
            staff_projects: [],
            staff_project: {
                staff_work_project_id: null,
                st_id: null,
                work_project_id: null,
            },
            staffs: [],
            projects_to_sort: [],
            showSpecialStaffs: "false",
        }
    },
    beforeCreate(){
        auth("workProjects")
    },
    created() {
        this.$http.post('/staff/getData').then(({data})=>{
            this.staffs = data.filter(obj => obj.show_staff == '1')
        })
    },
    mounted() {
        this.$http.post('/work_projects/getData').then(r => {
            this.work_projects = r.data
            this.projects_to_sort = JSON.parse(JSON.stringify(this.work_projects.filter(x => x.work_project_status == 'enabled')))

            this.$http.get('/work_projects/getStaffProjects').then(r => {
                this.staff_projects = r.data
            })
        })
    },
    computed: {
        filteredStaffProjects() {
            return this.staff_projects.filter(f => f.special_staff == this.showSpecialStaffs).sort((a, b) => a.staff_sort_code - b.staff_sort_code)
        }
    },
    methods: {
        clearProject(){
            this.work_project = {
                work_project_id: null,
                work_project_name: null,
                work_project_status: 'enabled'
            }
        },
        addProject(){
            if(!this.work_project.work_project_name) {
                alert('Please enter project name')
                return 
            }
            this.loading_add_project = true

            this.$http.post('/work_projects/addProject', this.work_project).then(r => {
                this.work_projects.push({
                    work_project_id: r.data.work_project_id,
                    work_project_name: this.work_project.work_project_name,
                    work_project_status: this.work_project.work_project_status
                })

                this.projects_to_sort.push({
                    work_project_id: r.data.work_project_id,
                    work_project_name: this.work_project.work_project_name,
                    work_project_status: this.work_project.work_project_status
                })
                this.clearProject()
            }).catch(() => {
                alert('Failed to add project')
            }).finally(() => {
                this.loading_add_project = false
            })
        },
        getSingleProject(item){
            this.work_project = JSON.parse(JSON.stringify(item))
        },
        updateProject() {
            if(!this.work_project.work_project_name) {
                alert('Please enter project name')
                return 
            }
            this.loading_add_project = true

            this.$http.patch('/work_projects/updateProject/' + this.work_project.work_project_id, this.work_project).then(() => {
                const index = this.work_projects.findIndex(x => x.work_project_id == this.work_project.work_project_id)
                this.$set(this.work_projects, index, JSON.parse(JSON.stringify(this.work_project)))
                alert('Project updated successfully')
            }).catch(() => {
                alert('Failed to update project')
            }).finally(() => {
                this.loading_add_project = false
            })
        },
        deleteProject(item) {
            const c = confirm('Are you sure you want to delete this project?')
            if(c) {
                this.$http.delete('/work_projects/deleteProject/' + item.work_project_id).then(() => {
                    this.work_projects = this.work_projects.filter(x => x.work_project_id != item)
                }).catch(() => {
                    alert('Failed, Project may be used in other records')
                })
            }
        },
        updateStatus(id, status) {
            if(status == 'enabled') {
                const c = confirm('Are you sure you want to enable this project?')
                if(!c) return
            } else {
                const c = confirm('Are you sure you want to disable this project?')
                if(!c) return
            }

            this.$http.patch('/work_projects/updateStatus/' + id, {work_project_status: status}).then(() => {
                const index = this.work_projects.findIndex(x => x.work_project_id == id)
                this.$set(this.work_projects[index], 'work_project_status', status)

                if(status == 'enabled') {
                    this.projects_to_sort.push(this.work_projects[index])
                } else {
                    this.projects_to_sort = this.projects_to_sort.filter(x => x.work_project_id != id)
                }
            }).catch(() => {
                alert('Failed to update status')
            })
        },
        clearStaffProject() {
            this.staff_project = {
                staff_work_project_id: null,
                st_id: null,
                work_project_id: null
            }
        },
        selectSingleStaffProject(item) {
            this.staff_project = JSON.parse(JSON.stringify(item))
        },
        updateStaffProject() {
            if(!this.staff_project.st_id || !this.staff_project.work_project_id) {
                alert('Please select staff and project')
                return
            }

            this.$http.patch('/work_projects/updateStaffProject/' + this.staff_project.staff_work_project_id, {
                work_project_id: this.staff_project.work_project_id
            }).then(() => {
                const index = this.staff_projects.findIndex(x => x.staff_work_project_id == this.staff_project.staff_work_project_id)
                this.$set(this.staff_projects[index], 'work_project_id', this.staff_project.work_project_id)
                this.$set(this.staff_projects[index], 'work_project_name', this.work_projects.find(x => x.work_project_id == this.staff_project.work_project_id).work_project_name)
                alert('Staff project updated successfully')
            }).catch(() => {
                alert('Failed to update staff project')
            })
        },
        saveProjectsSort() {
            this.$http.post('/work_projects/saveSortProjects', {
                list: this.projects_to_sort
            }).then(() => {
                for(let i = 0; i < this.projects_to_sort.length; i++) {
                    const index = this.work_projects.findIndex(x => x.work_project_id == this.projects_to_sort[i].work_project_id)
                    this.$set(this.work_projects[index], 'sort_code', i)
                }
                this.work_projects = this.work_projects.sort((a, b) => a.sort_code - b.sort_code)
                this.$alert('Successfully', "Success", "success");
            }).catch((err) => {
                showError(err);
            })
        }
    }
}

</script>